import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import banner from '../assets/images/PPE4ALLBanner.png'

class Corporate extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Corporate | PWR 4" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={banner} alt="" /></span>
            <h2>A decentralized network of volunteers and creators.</h2>
            <p>We produce face shields to protect at-risk populations across the globe and prevent the spread of the coronavirus. We also aim to streamline the collection of PPE contributions and distribute them to those who need it most.</p>
            <p>When the majority of our team members were sent home from college in March, feeling helpless in the face of the growing pandemic seemed inevitable. The urgency of the need to protect health care and essential workers became a call to action during a time when we were all trapped in limbo, searching for a sense of direction and purpose.</p>
            <h2>A national community: from coast-to-coast.</h2>
            <p>We connect with business owners, people with 3D printing capabilities, manufacturers, and regular folks to make this mission a success. Formerly PPE4NYC, we have expanded to be able to provide PPE to anyone who otherwise had limited access to it. Our partners, team members, and suppliers are representative of the diverse communities we serve, hailing from across the country.</p>
            <a href="https://ppe4all.net">Visit our site</a>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Corporate
